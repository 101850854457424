import React from 'react';
import axios from 'axios';

import * as AppConstants from "../config/AppConstants";

const ApiContext = React.createContext();

export const ApiProvider = ({ children }) => {

    // Create default instace of Axios with Base API URL
    const apiService = axios.create({
        baseURL: AppConstants.BASE_API_URL,
    });

    const detectAppStageEnvironmentHeader = (token) => {
        if (process.env.REACT_APP_STAGE === "dev") {
            return { "Content-Type": "application/json", DevelopmentAuth: 'Bearer ' + token };
        } else return { "Content-Type": "application/json", Authorization: 'Bearer ' + token };
    };

    const detectAppStageEnvironmentHeaderForFiles = (token) => {
        if (process.env.REACT_APP_STAGE === "dev") {
            return { "Content-Type": "multipart/form-data", DevelopmentAuth: 'Bearer ' + token, "X-Requested-With": "XMLHttpRequest" };
        } else return { "Content-Type": "multipart/form-data", Authorization: 'Bearer ' + token, "X-Requested-With": "XMLHttpRequest" };
    };

    // All Endpoint URL paths defined
    const registerAccountPath = "/register";
    const changePasswordPath = "/account/change-password";
    const resetPasswordPath = "/account/reset-password/init";
    const activateAccountPath = "/activate?key=";
    const getCategoriesPath = "/categories";
    const getCitiesPath = "/cities";
    const updateAccountPath = "/account";
    const createListingPath = "/listings";
    const attachPhotosPath = "/listings/{listingId}/photos";
    const listingsForCategoryPath = "/listings/category/{categoryId}";
    const myListingsPath = "/listings/my";
    const singleListingPath = "/listings/{listingId}";

    // All Required headers for each Endpoint
    const authenticatedHeaders = { headers: detectAppStageEnvironmentHeader(localStorage.getItem("auth_token")) };
    const authenticatedFileUploadHeaders = {
        headers: detectAppStageEnvironmentHeaderForFiles(localStorage.getItem("auth_token"))
    };
    const resetPasswordHeaders = { headers: { "Content-Type": "text/plain" } }


    // Exported functions to invoke request per each Endpoint
    const registerAccountRequest = body => apiService.post(registerAccountPath, body);
    const changePasswordRequest = body => apiService.post(changePasswordPath, body, authenticatedHeaders);
    const resetPasswordRequest = email => apiService.post(resetPasswordPath, email, resetPasswordHeaders);
    const activateAccountRequest = activationKey => apiService.get(activateAccountPath + activationKey);
    const getCategoriesRequest = () => apiService.get(getCategoriesPath);
    const getCitiesRequest = () => apiService.get(getCitiesPath);
    const updateAccountRequest = body => apiService.post(updateAccountPath, body, authenticatedHeaders);
    const createListingRequest = body => apiService.post(createListingPath, body, authenticatedHeaders);

    const attachPhotosToListingRequest = (photoFile, listingId, onUploadProgress) => {
        let formData = new FormData();
        formData.append("photoFile", photoFile);
        return apiService.post(
            attachPhotosPath.replace("{listingId}", listingId),
            formData,
            { ...authenticatedFileUploadHeaders, onUploadProgress }
        );
    };

    const getListingsForCategory = categoryId => apiService.get(listingsForCategoryPath.replace("{categoryId}", categoryId));
    const getMyListingsRequest = () => apiService.get(myListingsPath, authenticatedHeaders);
    const getSingleListingRequest = listingId => apiService.get(singleListingPath.replace("{listingId}", listingId));


    const contextPayload = {
        registerAccountRequest, changePasswordRequest, resetPasswordRequest,
        activateAccountRequest, getCategoriesRequest, getCitiesRequest,
        updateAccountRequest, createListingRequest, attachPhotosToListingRequest,
        getListingsForCategory, getMyListingsRequest, getSingleListingRequest
    };

    return (
        <ApiContext.Provider value={contextPayload}>
            {children}
        </ApiContext.Provider>
    )
}

export const useApiContext = () => React.useContext(ApiContext);
