import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
import Page404 from '../error/Page404';
import ForbiddenInvite from '../error/ForbiddenInvite';

function useQuery() {
    return new URLSearchParams(useLocation().search);
};

function PublicRoute({ component: Component, publicOnly, path, ...rest }) {
    const urlQuery = useQuery();
    const { auth } = useAuthContext();

    return <Route path={path} {...rest} component={props => {
        if ((publicOnly && auth.isAuthenticated) && (!!urlQuery.get("code") && path === "/registracija")) {
            return <ForbiddenInvite user={auth.currentUser} />
        } else if (publicOnly && auth.isAuthenticated) {
            return <Page404 />
        }
        return <Component {...props} />;
    }} />;
}

export default PublicRoute;