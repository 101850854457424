import React from 'react';
import axios from 'axios';

import * as AppConstants from '../config/AppConstants'
import useInterval from '@rooks/use-interval';
import api from '../../shared/client/api';
import { useToastContext } from '../state/ToastContext';
import { googleLogout } from '@react-oauth/google';
import { isValidEmail } from '../util/ValidationUtil';

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {

    const { notify, setContextStyleColor } = useToastContext();

    const emptyAuth = React.useMemo(() => ({
        isAuthenticated: false,
        currentUser: undefined,
        validationError: false,
        credentialsError: false,
        loginErrorMessage: ""
    }), []);
    const [accountSettings, setAccountSettings] = React.useState(null);

    const [auth, setAuth] = React.useState({
        isAuthenticated: false,
        currentUser: undefined,
        validationError: false,
        credentialsError: false,
        loginErrorMessage: ""
    });

    const detectAppStageEnvironmentHeader = (token) => {
        if (process.env.REACT_APP_STAGE === "dev") {
            return { DevelopmentAuth: 'Bearer ' + token };
        } else return { Authorization: 'Bearer ' + token };
    };

    const isTokenSet = React.useCallback(() => {
        const token = localStorage.getItem('auth_token');
        if (token == null || token === undefined || token === 'null' || token === 'undefined'
            || token === false || token === 'false' || Boolean(token) === false) {
            return false;
        }
        return true;
    }, []);

    const fetchAccountSettings = React.useCallback(() => {
        axios
        .get(AppConstants.BASE_API_URL + '/account-settings', { headers: detectAppStageEnvironmentHeader(localStorage.getItem("auth_token")) })
        .then(response => response.data)
        .then(response => setAccountSettings(response.data))
        .catch(error => {/* ignore */});
    }, []);

    const fetchAccount = React.useCallback((fetchToken) => {

        const token = !!fetchToken ? fetchToken : localStorage.getItem("auth_token");

        return axios.get(AppConstants.BASE_API_URL + '/account', { headers: detectAppStageEnvironmentHeader(token) })
            .then(response => {
                if (!!localStorage.getItem("cart_buyer_initiate") && parseInt(localStorage.getItem("cart_buyer_initiate")) !== response.data.id) {
                    localStorage.removeItem("cart_buyer_initiate");
                    localStorage.setItem("shopping_cart", JSON.stringify({
                        sellers: [],
                        total: 0,
                        lastUpdate: new Date()
                    }))
                };

                if (!!localStorage.getItem("cart_buyer_variation_initiate") && parseInt(localStorage.getItem("cart_buyer_variation_initiate")) !== response.data.id) {
                    localStorage.removeItem("cart_buyer_variation_initiate");
                    localStorage.setItem("shopping_cart_variation", JSON.stringify({
                        sellers: [],
                        total: 0,
                        lastUpdate: new Date()
                    }))
                };

                if (!localStorage.getItem("cart_buyer_initiate")) {
                    localStorage.removeItem("cart_buyer_initiate");
                    localStorage.setItem("shopping_cart", JSON.stringify({
                        sellers: [],
                        total: 0,
                        lastUpdate: new Date()
                    }));
                };

                if (!localStorage.getItem("cart_buyer_variation_initiate")) {
                    localStorage.removeItem("cart_buyer_variation_initiate");
                    localStorage.setItem("shopping_cart_variation", JSON.stringify({
                        sellers: [],
                        total: 0,
                        lastUpdate: new Date()
                    }));
                };

                setAuth({
                    ...auth,
                    isAuthenticated: true,
                    currentUser: response.data,
                    validationError: false,
                    credentialsError: false,
                    loginErrorMessage: ""
                });
            })
            .catch(error => {
                localStorage.removeItem("auth_token");
                setAuth({
                    ...emptyAuth,
                    validationError: false,
                    credentialsError: true,
                    loginErrorMessage: ""
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = React.useCallback((username, password) => {

        if (username.length === 0 || password.length === 0) {
            setAuth({
                ...auth,
                validationError: true,
                credentialsError: false
            });
        }
        else {
            // try to login
            axios
                .post(AppConstants.BASE_API_URL + '/authenticate', {
                    username: isValidEmail(username) ? "" : username,
                    email: isValidEmail(username) ? username : "",
                    password
                })
                .then(response => {
                    if (response.data.id_token) {
                        return response.data.id_token;
                    }
                    else {
                        setAuth({
                            ...auth,
                            credentialsError: true,
                            loginErrorMessage: ""
                        });
                    }
                })
                .then(token => {

                    // login successful, set cookie
                    localStorage.setItem("auth_token", token);
                    axios
                        .get(AppConstants.BASE_API_URL + '/account-settings', { headers: detectAppStageEnvironmentHeader(token) })
                        .then(response => response.data)
                        .then(response => setAccountSettings(response.data))
                        .catch(error => {/* ignore */});
                    return fetchAccount(token);
                })
                .catch(error => {
                    if (!!error.response && !!error.response.data && error.response.data.status === 400) {
                        setAuth({
                            ...emptyAuth,
                            loginErrorMessage: error.response.data.detail
                        });                     
                    } else {
                        setAuth({
                            ...emptyAuth,
                            validationError: false,
                            credentialsError: true,
                            loginErrorMessage: ""
                        });
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loginOAuth = React.useCallback((payload) => {
        // try to login
        axios
            .post(AppConstants.BASE_API_URL + '/authenticate/oauth', payload)
            .then(response => {
                if (response.data.id_token) {
                    return response.data.id_token;
                }
                else {
                    setAuth({
                        ...auth,
                        credentialsError: true,
                        loginErrorMessage: ""
                    });
                }
                })
                .then(token => {
                    // login successful, set cookie
                    localStorage.setItem("auth_token", token);
                    axios
                        .get(AppConstants.BASE_API_URL + '/account-settings', { headers: detectAppStageEnvironmentHeader(token) })
                        .then(response => response.data)
                        .then(response => setAccountSettings(response.data))
                        .catch(error => {/* ignore */});
                        return fetchAccount(token);
                    })
                    .catch(error => {
                    if (!!error.response && !!error.response.data && error.response.data.status === 400) {
                        setAuth({
                            ...emptyAuth,
                            loginErrorMessage: error.response.data.detail
                        });                     
                } else {
                    setAuth({
                        ...emptyAuth,
                        validationError: false,
                        credentialsError: true,
                        loginErrorMessage: ""
                    });
                }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logoutInternal = React.useCallback(() => {
        setAuth(emptyAuth);
        localStorage.removeItem('auth_token');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = React.useCallback((email) => {
        api.put("/user/logout", {
            token: localStorage.getItem("auth_token"),
            email
        }, {
            headers: detectAppStageEnvironmentHeader(localStorage.getItem("auth_token"))
        })
        .then((response) => response)
        .catch((error) => console.error(error));
        setAuth(emptyAuth);
        localStorage.removeItem('auth_token');
        googleLogout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logoutBannedUser = React.useCallback(() => {
        setAuth(emptyAuth);
        localStorage.removeItem('auth_token');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useInterval(() => {
        // check if is not authenticated but token exists
        if (isTokenSet() && !auth.isAuthenticated) {
            fetchAccount(localStorage.getItem("auth_token"));
        }

        // check if authenticated but no token
        if (!isTokenSet() && !!auth.isAuthenticated) {
            logoutInternal();
        }
    }, 5000, true);

    useInterval(() => {
        if ((!!auth && !!auth.isAuthenticated)) {
            axios.get(AppConstants.BASE_API_URL + '/account', { headers: detectAppStageEnvironmentHeader(localStorage.getItem("auth_token")) })
            .then(response => {
                if (!!response && !!response.data) {
                    if (response.data.id === auth.currentUser.id && !!response.data.banned) {
                        api.put("/user/logout", {
                            token: localStorage.getItem("auth_token"),
                            email: auth.currentUser.email
                        })
                        .then((response) => {
                            if (!!response && !!response.data) {
                                logoutBannedUser();   
                                setTimeout(() => {
                                    notify({
                                        text: "Vaš korisnički nalog je privremeno suspendovan! Provjerite Vaš email.",
                                        id: "user-banned-notification",
                                      });
                                      setContextStyleColor('bg-red');
                                }, 1500);
                            }
                        })
                        .catch((error) => console.error(error));
                    }
                }
            })
            .catch(error => error);
        }
    }, 10 * 60 * 1000, true);

    React.useEffect(() => {
        if (!auth.isAuthenticated && isTokenSet()) {
            axios.get(AppConstants.BASE_API_URL + '/account', { headers: detectAppStageEnvironmentHeader(localStorage.getItem("auth_token")) })
                .then(response => {
                    setAuth({
                        ...emptyAuth,
                        isAuthenticated: true,
                        currentUser: response.data,
                    });
                })
                .catch(error => {
                    logoutInternal();
                });
            axios.get(AppConstants.BASE_API_URL + '/account-settings', { headers: detectAppStageEnvironmentHeader(localStorage.getItem("auth_token")) })
            .then(response => response.data)
            .then(response => setAccountSettings(response.data))
            .catch(error => {/* ignore */});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const contextPayload = React.useMemo(() => ({ isTokenSet, fetchAccount, auth, accountSettings, fetchAccountSettings, login, loginOAuth, logout }), [isTokenSet, fetchAccount, auth, accountSettings, fetchAccountSettings, login, loginOAuth, logout]);

    return (
        <AuthContext.Provider value={contextPayload}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => React.useContext(AuthContext);
