import findIndex from 'lodash.findindex';
import isArray from 'lodash.isarray';
import React from 'react';
import api from '../client/api';
import { decryptResponseData } from '../util/CryptoDecryptionUtil';
import includes from 'lodash.includes';
import sortBy from 'lodash.sortby';
import filter from 'lodash.filter';
import { handleRootCategoriesWithValidTypes } from '../util/MiscUtil';

const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {

    const savedCart = JSON.parse(localStorage.getItem("shopping_cart"));
    const emptyCart = {
        sellers: [],
        total: 0,
        lastUpdate: new Date()
    };

    const savedViewMode = JSON.parse(localStorage.getItem("view_mode"));
    const emptyViewMode = { display: "grid" }; //

    const [viewMode, setViewMode] = React.useState(!!savedViewMode ? savedViewMode : emptyViewMode);

    // save view mode to local storage on every change
    React.useEffect(() => {
        localStorage.setItem("view_mode", JSON.stringify(viewMode));
    }, [viewMode]);

    const [categories, setCategories] = React.useState(null);
    const [saleType, setSaleType] = React.useState([]);
    const [rentType, setRentType] = React.useState([]);
    const [jobType, setJobType] = React.useState([]);
    const [serviceType, setServiceType] = React.useState([]);
    const [giftType, setGiftType] = React.useState([]);
    const [shoppingCart, setShoppingCart] = React.useState(!!savedCart ? savedCart : emptyCart);

    const extractPathFromCategoriesMatchedByRoot = React.useCallback((allCategories, root) => {
        let extractedPaths = [];
        let prepared = [];
        allCategories.forEach(category => {
            root.forEach(parent => {
                if (parseInt(category['path'].split('.')[0]) === parseInt(parent.id) && !!category['fillable']) {
                    extractedPaths.push(category['path']);
                }
            })
        });

        // extract slugs second part
        extractedPaths.forEach(path => {
            let slug = [];
            const splitted = path.split('.');
            splitted.forEach(id => {
                for (let i in allCategories) {
                    if (parseInt(allCategories[i].id) === parseInt(id)) {
                        slug.push(allCategories[i]['name'].trim());
                        break;
                    }
                }
            });
            prepared.push({
                value: path,
                label: slug.join(" / "),
                name: slug
            })
        });
        const finalSorted = sortBy(prepared, ['name'], ['ASC'])
        return finalSorted;
    }, []);

    React.useEffect(() => {
        if (!categories) {
            api.get("/categories")
                .then(response => {
                    if (!!response.data && !!response.data) {
                        const decryptedCategories = decryptResponseData(response.data);
                        setCategories(JSON.parse(decryptedCategories).data);
                    }
                })
                .catch(error => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const matchesType = (category, type) => {
        return includes(category.types, String(type).toUpperCase());
    };

    React.useEffect(() => {
        if (!!categories) {
            const typeRootCategoriesSale = filter(
                (isArray(handleRootCategoriesWithValidTypes()) ? filter(handleRootCategoriesWithValidTypes(), category => category.root) : []),
                category => matchesType(category, 'sale')
            );
            const typeRootCategoriesForRent = filter(
                (isArray(handleRootCategoriesWithValidTypes()) ? filter(handleRootCategoriesWithValidTypes(), category => category.root) : []),
                category => matchesType(category, 'rent')
            );
            const typeRootCategoriesForJob = filter(
                (isArray(handleRootCategoriesWithValidTypes()) ? filter(handleRootCategoriesWithValidTypes(), category => category.root) : []),
                category => matchesType(category, 'job')
            );
            const typeRootCategoriesForService = filter(
                (isArray(handleRootCategoriesWithValidTypes()) ? filter(handleRootCategoriesWithValidTypes(), category => category.root) : []),
                category => matchesType(category, 'service')
            );
            const typeRootCategoriesForGift = filter(
                (isArray(handleRootCategoriesWithValidTypes()) ? filter(handleRootCategoriesWithValidTypes(), category => category.root) : []),
                category => matchesType(category, 'gift')
            );
            setSaleType(extractPathFromCategoriesMatchedByRoot(categories, sortBy(typeRootCategoriesSale, ['name'], ['ASC'])));
            setRentType(extractPathFromCategoriesMatchedByRoot(categories, sortBy(typeRootCategoriesForRent, ['name'], ['ASC'])));
            setJobType(extractPathFromCategoriesMatchedByRoot(categories, sortBy(typeRootCategoriesForJob, ['name'], ['ASC'])));
            setServiceType(extractPathFromCategoriesMatchedByRoot(categories, sortBy(typeRootCategoriesForService, ['name'], ['ASC'])));
            setGiftType(extractPathFromCategoriesMatchedByRoot(categories, sortBy(typeRootCategoriesForGift, ['name'], ['ASC'])));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);

    const handleCartUpdate = React.useCallback(() => {
        setShoppingCart(emptyCart);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // save shopping cart to local storage on every change
    React.useEffect(() => {
        localStorage.setItem("shopping_cart", JSON.stringify(shoppingCart));
    }, [shoppingCart]);

    // validate cart against the server
    const validateCart = React.useCallback((cartToValidate, callback, errorCallback) => {
        api
            .post("/cart/validate", cartToValidate)
            .then(response => {
                const cart = !!response && !!response.data && !!response.data.data ? response.data.data : null;
                // if ve received response, set it as current cart state
                !!cart && setShoppingCart(cart);
                // call callbacks
                errorCallback(null);
                callback();
            })
            .catch(error => {
                // set error
                errorCallback("Desila se greška prilikom dodavanja artikla u korpu.");
                callback();
            })
    }, []);

    // validate cart against the server
    const validateCartSyncUpdate = React.useCallback((cartToValidate) => {
        api
            .post("/cart/validate", cartToValidate)
            .then(response => {
                const cart = !!response && !!response.data && !!response.data.data ? response.data.data : null;
                // if ve received response, set it as current cart state
                !!cart && setShoppingCart(cart);
                // call callbacks
            })
            .catch(error => {
                // set error
            })
    }, []);

    // validate cart against the server (advanced)
    const validateCartAdvanced = React.useCallback((cartToValidate, callback, errorCallback) => {
        api
            .post("/cart/validate", cartToValidate)
            .then(response => {
                const cart = !!response && !!response.data && !!response.data.data ? response.data.data : null;
                // if ve received response, set it as current cart state
                !!cart && setShoppingCart(cart);
                // call callbacks
                errorCallback(null);
                callback(cart);
            })
            .catch(error => {
                // set error
                errorCallback("Desila se greška.");
            })
    }, []);

    // routine to calculate seller subtotal
    const calculateSellerSubtotal = sellerItems => {
        return isArray(sellerItems) ? sellerItems.reduce((reduced, current) => {
            return (reduced + (parseFloat(current.price) * parseInt(current.quantity)));
        }, 0) : 0;
    }

    // routine to calculate number of sellers items
    const calculateSellerItems = sellerItems => {
        return isArray(sellerItems) ? sellerItems.reduce((reduced, current) => {
            return (reduced + (parseInt(current.quantity)));
        }, 0) : 0;
    }

    // routine to calculate cart total price
    const calculateCartTotalPrice = cart => {
        return isArray(cart.sellers) ? cart.sellers.reduce((reduced, current) => {
            return (reduced + parseFloat(current.subtotal));
        }, 0) : 0;
    }

    // routine to calculate cart total items
    const calculateCartTotalItems = cart => {
        return isArray(cart.sellers) ? cart.sellers.reduce((reduced, current) => {
            return (reduced + parseInt(current.sellerItems));
        }, 0) : 0;
    }

    const removeVariation = React.useCallback((listing, variationId, callback, errorCallback) => {
        if (!listing) {
            return;
        }
        const currentCart = { ...shoppingCart };
        const existingSellerIndex = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        currentCart.sellers[existingSellerIndex]["items"] = currentCart.sellers[existingSellerIndex]["items"].filter(item => item.variationId !== variationId);

        // remove sellers with zero items
        currentCart.sellers = !!currentCart.sellers ? currentCart.sellers.filter(seller => {
            if (!seller.items || seller.items.length === 0) {
                return false;
            }
            return true;
        }) : [];

        // find current seller index after operations
        const existingSellerIndexAfter = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        if (existingSellerIndexAfter > -1) {
            // recalculate seller totals
            currentCart.sellers[existingSellerIndexAfter]["subtotal"] =
                calculateSellerSubtotal(currentCart.sellers[existingSellerIndexAfter]["items"]).toFixed(2);
            currentCart.sellers[existingSellerIndexAfter]["sellerItems"] =
                calculateSellerItems(currentCart.sellers[existingSellerIndexAfter]["items"]);
        }
        // recalculate cart totals
        currentCart.total = calculateCartTotalPrice(currentCart).toFixed(2);
        currentCart.totalItems = calculateCartTotalItems(currentCart);
        // last update timestamp
        currentCart.lastUpdate = new Date();

        // lets validate current cart changes
        validateCart(currentCart, callback, errorCallback);

    }, [shoppingCart, validateCart]);

    const decrementVariation = React.useCallback((listing, qty, variationId, callback, errorCallback) => {
        if (!listing) {
            return;
        }
        const currentCart = { ...shoppingCart };
        const existingSellerIndex = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        const findExactVariation = currentCart.sellers[existingSellerIndex]["items"].find(item => item.variationId === variationId);
        const index = findIndex(currentCart.sellers[existingSellerIndex]["items"], a => a.variationId === findExactVariation.variationId);
        const oldQty = currentCart.sellers[existingSellerIndex]["items"][index]["quantity"];
        const newQty = parseInt(oldQty) + parseInt(qty);
        if (newQty > 0) {
            currentCart.sellers[existingSellerIndex]["items"][index]["quantity"] = newQty;
        } else {
            // item should be removed as quantity has been decremented to number less or equal to 01
            currentCart.sellers[existingSellerIndex]["items"] = currentCart.sellers[existingSellerIndex]["items"].filter(item => item.variationId !== variationId);
        }
        // remove sellers with zero items
        currentCart.sellers = !!currentCart.sellers ? currentCart.sellers.filter(seller => {
            if (!seller.items || seller.items.length === 0) {
                return false;
            }
            return true;
        }) : [];
        // find current seller index after operations
        const existingSellerIndexAfter = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        if (existingSellerIndexAfter > -1) {
            // recalculate seller totals
            currentCart.sellers[existingSellerIndexAfter]["subtotal"] =
                calculateSellerSubtotal(currentCart.sellers[existingSellerIndexAfter]["items"]).toFixed(2);
            currentCart.sellers[existingSellerIndexAfter]["sellerItems"] =
                calculateSellerItems(currentCart.sellers[existingSellerIndexAfter]["items"]);
        }
        // recalculate cart totals
        currentCart.total = calculateCartTotalPrice(currentCart).toFixed(2);
        currentCart.totalItems = calculateCartTotalItems(currentCart);
        // last update timestamp
        currentCart.lastUpdate = new Date();

        // lets validate current cart changes
        validateCart(currentCart, callback, errorCallback);

    }, [shoppingCart, validateCart]);

    const incrementVariation = React.useCallback((listing, qty, variationId, callback, errorCallback) => {
        if (!listing) {
            return;
        }
        const extractVariations = listing.variations.find(variation => variation.id === variationId);
        const currentCart = { ...shoppingCart };
        const existingSellerIndex = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        const findExactVariation = currentCart.sellers[existingSellerIndex]["items"].find(item => item.variationId === variationId);
        const newQty = parseInt(findExactVariation.quantity) + parseInt(qty);
        if (parseInt(extractVariations.quantityOnStock) < parseInt(newQty)) {
            errorCallback("U korpi već postoji " + findExactVariation.quantity + " ovih artikala. Nije moguće dodati u korpu veću količinu od dostupne na stanju.");
            callback();
            return;
        };
        const index = findIndex(currentCart.sellers[existingSellerIndex]["items"], a => a.variationId === findExactVariation.variationId);
        currentCart.sellers[existingSellerIndex]["items"][index]["quantity"] = newQty;
        // remove sellers with zero items
        currentCart.sellers = !!currentCart.sellers ? currentCart.sellers.filter(seller => {
            if (!seller.items || seller.items.length === 0) {
                return false;
            }
            return true;
        }) : [];
        // find current seller index after operations
        const existingSellerIndexAfter = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        if (existingSellerIndexAfter > -1) {
            // recalculate seller totals
            currentCart.sellers[existingSellerIndexAfter]["subtotal"] =
                calculateSellerSubtotal(currentCart.sellers[existingSellerIndexAfter]["items"]).toFixed(2);
            currentCart.sellers[existingSellerIndexAfter]["sellerItems"] =
                calculateSellerItems(currentCart.sellers[existingSellerIndexAfter]["items"]);
        }
        // recalculate cart totals
        currentCart.total = calculateCartTotalPrice(currentCart).toFixed(2);
        currentCart.totalItems = calculateCartTotalItems(currentCart);
        // last update timestamp
        currentCart.lastUpdate = new Date();

        // lets validate current cart changes
        validateCart(currentCart, callback, errorCallback);

    }, [shoppingCart, validateCart]);

    const addToCartVariation = React.useCallback((listing, qty, variationId, callback, errorCallback) => {
        if (!listing) {
            return;
        }
        const extractVariation = listing.variations.find(v => v.id === parseInt(variationId));
        const currentCart = { ...shoppingCart };
        const existingSellerIndex = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        if (existingSellerIndex < 0) {
            // this seller does not exist in shopping cart
            if (parseInt(qty) > 0) {
                const newSeller = {
                    sellerId: listing.owner.id,
                    items: [
                        {
                            itemId: listing.id,
                            title: listing.title,
                            variationTitle: extractVariation.title,
                            price: listing.price,
                            variationId: extractVariation.id,
                            quantity: qty,
                            quantityOnStock: extractVariation.quantityOnStock
                        }
                    ]
                };
                !!currentCart.sellers ? currentCart.sellers = [...currentCart.sellers, newSeller] : currentCart.sellers = [newSeller];
            }
        } else {
            const existingItemIndex = findIndex(currentCart.sellers[existingSellerIndex]["items"], item => parseInt(item.variationId) === parseInt(variationId));

            if (existingItemIndex < 0) {
                const constructNewItem = {                   
                    itemId: listing.id,
                    title: listing.title,
                    variationTitle: extractVariation.title,
                    price: listing.price,
                    variationId: extractVariation.id,
                    quantity: qty,
                    quantityOnStock: extractVariation.quantityOnStock
                };
                currentCart.sellers[existingSellerIndex]["items"].push(constructNewItem);
            } else {
                const extractItemWithVariation = currentCart.sellers[existingSellerIndex]["items"][existingItemIndex]["variationId"];
                if (parseInt(extractItemWithVariation) === parseInt(variationId)) {
                    const oldQty = currentCart.sellers[existingSellerIndex]["items"][existingItemIndex]["quantity"];
                    const extractOriginalQuantity = currentCart.sellers[existingSellerIndex]["items"][existingItemIndex]["quantityOnStock"];
                    const newQty = parseInt(oldQty) + parseInt(qty);
                if (parseInt(extractOriginalQuantity) < parseInt(newQty)) {
                    errorCallback("U korpi već postoji " + oldQty + " ovih artikala. Nije moguće dodati u korpu veću količinu od dostupne na stanju.");
                    callback();
                    return;
                }
                if (newQty > 0) {
                    currentCart.sellers[existingSellerIndex]["items"][existingItemIndex]["quantity"] = newQty;
                } else {
                    // item should be removed as quantity has been decremented to number less or equal to 0
                    currentCart.sellers[existingSellerIndex]["items"] = currentCart.sellers[existingSellerIndex]["items"].filter(item => item.variationId !== variationId);
                }
            } else {
                const constructNewItem = {                   
                    itemId: listing.id,
                    title: listing.title,
                    variationTitle: extractVariation.title,
                    price: listing.price,
                    variationId: extractVariation.id,
                    quantity: qty,
                    quantityOnStock: extractVariation.quantityOnStock
                };
                currentCart.sellers[existingSellerIndex]["items"].push(constructNewItem);
            }
          }            
        }
        // remove sellers with zero items
        currentCart.sellers = !!currentCart.sellers ? currentCart.sellers.filter(seller => {
            if (!seller.items || seller.items.length === 0) {
                return false;
            }
            return true;
        }) : [];
        // find current seller index after operations
        const existingSellerIndexAfter = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        if (existingSellerIndexAfter > -1) {
            // recalculate seller totals
            currentCart.sellers[existingSellerIndexAfter]["subtotal"] =
                calculateSellerSubtotal(currentCart.sellers[existingSellerIndexAfter]["items"]).toFixed(2);
            currentCart.sellers[existingSellerIndexAfter]["sellerItems"] =
                calculateSellerItems(currentCart.sellers[existingSellerIndexAfter]["items"]);
        }
        // recalculate cart totals
        currentCart.total = calculateCartTotalPrice(currentCart).toFixed(2);
        currentCart.totalItems = calculateCartTotalItems(currentCart);
        // last update timestamp
        currentCart.lastUpdate = new Date();
        
        // lets validate current cart changes
        validateCart(currentCart, callback, errorCallback);

    }, [shoppingCart, validateCart]);

    /**
     * Method for adding/removing items to the shopping cart
     */
    const addToCart = React.useCallback((listing, qty, callback, errorCallback) => {
        if (!listing) {
            return;
        }
        const currentCart = { ...shoppingCart };
        const existingSellerIndex = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        if (existingSellerIndex < 0) {
            // this seller does not exist in shopping cart
            if (parseInt(qty) > 0) {
                const newSeller = {
                    sellerId: listing.owner.id,
                    items: [
                        {
                            itemId: listing.id,
                            title: listing.title,
                            price: listing.price,
                            quantity: qty
                        }
                    ]
                };
                !!currentCart.sellers ? currentCart.sellers = [...currentCart.sellers, newSeller] : currentCart.sellers = [newSeller];
            }
        } else {
            // this seller does exist in shopping cart
            const existingItemIndex = findIndex(currentCart.sellers[existingSellerIndex]["items"], item => item.itemId === listing.id);
            if (existingItemIndex > -1) {
                // item exists in cart
                const oldQty = currentCart.sellers[existingSellerIndex]["items"][existingItemIndex]["quantity"];
                const newQty = parseInt(oldQty) + parseInt(qty);
                if (parseInt(listing.quantityOnStock) < parseInt(newQty)) {
                    errorCallback("U korpi već postoji " + oldQty + " ovih artikala. Nije moguće dodati u korpu veću količinu od dostupne na stanju.");
                    callback();
                    return;
                };
                if (newQty > 0) {
                    currentCart.sellers[existingSellerIndex]["items"][existingItemIndex]["quantity"] = newQty;
                } else {
                    // item should be removed as quantity has been decremented to number less or equal to 0
                    currentCart.sellers[existingSellerIndex]["items"] = currentCart.sellers[existingSellerIndex]["items"].filter(item => item.itemId !== listing.id);
                }
            } else {
                // item does not exist in cart
                if (parseInt(qty) > 0) {
                    currentCart.sellers[existingSellerIndex]["items"] = [
                        ...currentCart.sellers[existingSellerIndex]["items"],
                        {
                            itemId: listing.id,
                            title: listing.title,
                            price: listing.price,
                            quantity: qty
                        }
                    ];
                }
            }
        }
        // remove sellers with zero items
        currentCart.sellers = !!currentCart.sellers ? currentCart.sellers.filter(seller => {
            if (!seller.items || seller.items.length === 0) {
                return false;
            }
            return true;
        }) : [];
        // find current seller index after operations
        const existingSellerIndexAfter = findIndex(currentCart.sellers, seller => seller.sellerId === listing.owner.id);
        if (existingSellerIndexAfter > -1) {
            // recalculate seller totals
            currentCart.sellers[existingSellerIndexAfter]["subtotal"] =
                calculateSellerSubtotal(currentCart.sellers[existingSellerIndexAfter]["items"]).toFixed(2);
            currentCart.sellers[existingSellerIndexAfter]["sellerItems"] =
                calculateSellerItems(currentCart.sellers[existingSellerIndexAfter]["items"]);
        }
        // recalculate cart totals
        currentCart.total = calculateCartTotalPrice(currentCart).toFixed(2);
        currentCart.totalItems = calculateCartTotalItems(currentCart);
        // last update timestamp
        currentCart.lastUpdate = new Date();

        // lets validate current cart changes
        validateCart(currentCart, callback, errorCallback);

    }, [shoppingCart, validateCart]);

    // removes a seller from a cart, usually after successful order creation
    const removeSellerFromCart = React.useCallback((sellerId, callback, errorCallback) => {
        const filteredSellers = [...shoppingCart.sellers].filter(seller => String(seller.sellerId) !== String(sellerId))
        const newShoppingCart = { ...shoppingCart, sellers: filteredSellers };
        // recalculate cart totals
        newShoppingCart.total = calculateCartTotalPrice(newShoppingCart).toFixed(2);
        newShoppingCart.totalItems = calculateCartTotalItems(newShoppingCart);
        // last update timestamp
        newShoppingCart.lastUpdate = new Date();
        // lets validate current cart changes
        validateCart(newShoppingCart, callback, errorCallback);

    }, [shoppingCart, validateCart]);

    const contextPayload = React.useMemo(() => ({
        categories,
        saleType,
        rentType,
        jobType,
        serviceType,
        giftType,
        shoppingCart,
        addToCart,
        addToCartVariation,
        decrementVariation,
        incrementVariation,
        removeVariation,
        validateCart,
        validateCartAdvanced,
        validateCartSyncUpdate,
        removeSellerFromCart,
        viewMode,
        setViewMode,
        handleCartUpdate,
    }), [categories, saleType, rentType, jobType, serviceType, giftType, shoppingCart, addToCart, addToCartVariation, decrementVariation, incrementVariation, removeVariation, validateCart, validateCartAdvanced, validateCartSyncUpdate, removeSellerFromCart, viewMode, setViewMode, handleCartUpdate]);

    return (
        <GlobalContext.Provider value={contextPayload}>
            {children}
        </GlobalContext.Provider>
    )
}

export const useGlobalContext = () => React.useContext(GlobalContext);
