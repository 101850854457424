import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../components/basic/Button';
import RobaSvg from '../../assets/images/RobaSvg';

function ForbiddenInvite({ user }) {
    return (
        <div className="sm:h-full md:h-screen lg:h-screen flex items-center justify-center">
            <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
                <div className="max-w-md">
                    <div className="py-4 text-5xl font-dark font-bold">Greška 403</div>
                    <p className="py-4 text-xl md:text-xl font-light leading-normal">Već ste prijavljeni na stranici kao korisnik {!!user && user.login}!
                    Nije moguće pristupiti stranici za registraciju već prijavljenim korisnicima. Pozivnice prijateljima sa linkom za registraciju su namijenjene isključivo neregistrovanim korisnicima.</p>
                    <p className="py-4 text-xl md:text-xl font-light leading-normal">
                    U slučaju da imate sekundarnu email adresu i želite kreirati novi korisnički nalog potrebno je prvobitno da se odjavite sa platforme Roba.ba
                    </p>
                    <p className="py-3"></p>
                    <NavLink to="/" title="Nazad na početnu"><Button variant="primary">Nazad na početnu</Button></NavLink>
                </div>
                <div className="max-w-lg">
                    <RobaSvg className="w-72 mt-12 md:w-60 md:ml-16 md:mt-0 lg:w-96 lg:ml-20 lg:mt-0" />
                </div>
            </div>
        </div>
    );
}

export default ForbiddenInvite;