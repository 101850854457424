import Loadable from 'react-loadable';
import { ThreeDots } from 'react-loader-spinner';
import Button from '../components/basic/Button';

function Loading(props) {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem("page-has-been-force-refreshed") || "false"
  );
  if (props.error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      // Assuming that the user is not on the latest version of the application.
      // Let's refresh the page immediately.
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      caches.keys().then(async function(names) {
        await Promise.all(names.map(name => caches.delete(name)));
      });
      window.localStorage.setItem("page-has-been-force-refreshed", "true");
      return window.location.reload();
    } else {
      return (
        <div className="w-full h-screen overflow-hidden flex flex-col items-center justify-center">
          <p className="text-center text-purple text-xl font-semibold">
            Desila se neočekivana greška prilikom učitavanja stranice!
          </p>
          <Button variant="primary" additionalClasses="mt-4" onClick={props.retry}>Učitaj ponovo</Button>
        </div>
      );
    }
  } else if (props.timedOut) {
    return (
      <div className="w-full h-screen overflow-hidden flex flex-col items-center justify-center">
        <p className="text-center text-purple text-xl font-semibold">
          Učitavanje stranice traje predugo!
        </p>
        <Button variant="primary" additionalClasses="mt-4" onClick={props.retry}>Učitaj ponovo</Button>
      </div>
    );
  } else if (props.pastDelay) {
    window.localStorage.setItem("page-has-been-force-refreshed", "false");
    return (
      <div className="w-full h-screen overflow-hidden flex flex-col items-center justify-center">
        <ThreeDots
          height="50"
          width="50"
          radius="9"
          color="#5e418f"
          ariaLabel="three-dots-loading"
          visible={true}
        />
        <p className="text-center text-purple text-xl font-semibold">
          Učitavanje...
        </p>
      </div>
    );
  } else {
    window.localStorage.setItem("page-has-been-force-refreshed", "false");
    return null;
  }
}

  export default function AsyncLoader(opts) {
    return Loadable(Object.assign({
      loading: Loading,
    }, opts));
  };