import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import * as AppConstants from '../../shared/config/AppConstants'
import { useAuthContext } from '../auth/AuthContext';
import Page404 from '../error/Page404';

function PrivateRoute({ component: Component, hasAnyAuthority, authenticated, company, redirectToLogin, redirectToHome, redirectToProfile, path, ...rest }) {

    const { auth } = useAuthContext();
    const { currentUser } = auth;

    const authoritiesMatch = () => {
        if (Array.isArray(hasAnyAuthority)) {
            const matches = hasAnyAuthority.filter(requestedAuthority => {
                if (currentUser && currentUser.authorities && Array.isArray(currentUser.authorities)) {
                    return currentUser.authorities.find(userAuthority => userAuthority === requestedAuthority) !== undefined;
                }
                return false;
            })
            return matches.length > 0;
        }
        return false;
    }

    const authenticatedOnly = !hasAnyAuthority && authenticated && auth.isAuthenticated;
    const authenticatedWithAuthority = auth.isAuthenticated && authoritiesMatch();

    return <Route path={path} {...rest} component={props => {
        if (authenticatedOnly || authenticatedWithAuthority) {
            return <Component {...props} />;
        }
        if (redirectToLogin) {
            const redirectToLoginUrl = (window.location.pathname + window.location.search);
            return <Redirect to={AppConstants.LOGIN_URL + '?redirectTo=' + encodeURIComponent(redirectToLoginUrl)} />
        } else if (redirectToHome) {
            return <Redirect to={AppConstants.HOME_URL} />
        } else if (redirectToProfile) {
            return <Redirect to={AppConstants.MY_ACCOUNT_URL} />
        } else {
            return <Page404 />
        }
    }} />;
}

export default PrivateRoute;