import React from 'react';

function Button({ additionalClasses, children, variant, size, disabled, type, ...rest }) {
    let classes = "rounded-md transition outline-none focus:outline-none ring-0 focus:ring-0 ";

    switch (size) {
        case "small":
            classes += " py-1 px-2 text-sm ";
            break;
        case "xs":
            classes += " py-0.5 px-1 ";
            break;
        case "n":
            classes += " p-0 text-sm ";
            break;
        default:
            classes += " py-2 px-4 ";
            break;
    }

    switch (variant) {
        case "primary":
            classes += "font-semibold bg-purple hover:bg-purple-dark text-white ";
            break;
        case "secondary":
            classes += "font-semibold bg-yellow hover:bg-yellow-dark text-gray-darker hover:text-gray-darkest ";
            break;
        case "tertiary":
            classes += "font-semibold bg-white hover:bg-gray-lightest text-gray-darker hover:text-gray-darkest ";
            break;
        case "success":
            classes += "font-semibold bg-green-500 hover:bg-green-600 text-white ";
            break;
        case "danger":
            classes += "font-semibold bg-red-500 hover:bg-red-600 text-white ";
            break;
        default:
            break;
    }

    if (!!additionalClasses) {
        classes += additionalClasses;
    }

    const disabledClasses = classes + " text-gray";

    if (disabled) {
        return (
            <button className={disabledClasses} type={!!type ? type : "button"} disabled {...rest} aria-label="Dugme">{children}</button>
        );
    }

    return (
        <button className={classes} type={!!type ? type : "button"} {...rest} aria-label="Dugme">{children}</button>
    );
}

export default Button;
