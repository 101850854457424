import axios from "axios";
import * as AppConstants from "../config/AppConstants";

const getToken = () => {
    const token = localStorage.getItem("auth_token");
    return !!token ? token : null;
}

const api = axios.create({
    timeout: 900000,
    baseURL: AppConstants.BASE_API_URL
});

const detectAppStageEnvironmentHeader = (config, token) => {
    if (process.env.REACT_APP_STAGE === "dev") {
        return config.headers.DevelopmentAuth = "Bearer " + token;
    } else return config.headers.Authorization = "Bearer " + token;
};

api.interceptors.request.use(function (config) {
    const token = getToken();

    if (!!token) {
        detectAppStageEnvironmentHeader(config, token);
    }

    return config;
});

export default api;
