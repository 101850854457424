export const isValid = form => {
    const violations = form.errors;
    if (Array.isArray(violations) && violations.length > 0) {
        return false;
    }
    return true;
}

export const fieldErrors = (form, field) => {
    if (Array.isArray(form.errors)) {
        return form.errors.filter(error => error.field === field);
    }
    return [];
}

export const fieldHasErrors = (form, field) => {
    return fieldErrors(form, field).length > 0;
}

export const formErrors = (form) => {
    return fieldErrors(form.errors, '');
}

export const isValidEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const isValidLogin = login => {
    const re = /^([a-zA-Z0-9_.]+)$/;
    return re.test(String(login))
}

export const isValidPhoneNumber = phone => {
    const re = /\+[0-9]+/;
    return re.test(phone)
}
