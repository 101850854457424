import { AES, enc, mode, pad } from "crypto-js";

export const decryptResponseData = (response) => {
    const decryptedData = AES.decrypt(
        response,
        enc.Base64.parse(process.env.REACT_APP_SHA256_SECRET_KEY),
        {
            iv: enc.Base64.parse(process.env.REACT_APP_IV_VECTOR_KEY),
            mode: mode.CBC,
            padding: pad.Pkcs7
        }
    );

    return decryptedData.toString(enc.Utf8);
}