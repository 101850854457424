const detectAppStageEnvironmentApi = () => {

    switch (process.env.REACT_APP_STAGE) {
        case "local":
            return "http://localhost:3000/v1/api";
        case "dev":
            if (window.location.protocol === "http:") {
                return "http://dev-frontend.roba.ba:10082/v1/api";
            } else {
                return "https://dev-frontend.roba.ba:10443/v1/api";
            }
        case "uat":
            return "NOT EXIST YET";
        case "prod":
            if (window.location.protocol === "http:") {
                return "http://www.roba.ba:10082/v1/api";
            } else {
                return "https://www.roba.ba/v1/api";
            }
        default:
            return "NOT SUPPORTED ENVIRONMENT";
    }
};

const detectAppStageEnvironmentApp = () => {

    switch (process.env.REACT_APP_STAGE) {
        case "local":
            return "http://localhost:3000";
        case "dev":
            if (window.location.protocol === "http:") {
                return "http://dev-frontend.roba.ba:10082";
            } else {
                return "https://dev-frontend.roba.ba:10443";
            }
        case "uat":
            return "NOT EXIST YET";
        case "prod":
            if (window.location.protocol === "http:") {
                return "http://www.roba.ba:10082";
            } else {
                return "https://www.roba.ba";
            }
        default:
            return "NOT SUPPORTED ENVIRONMENT";
    }
};

export const BASE_API_URL = detectAppStageEnvironmentApi(); 
export const BASE_APP_URL = detectAppStageEnvironmentApp();

export const HOME_URL = "/";

export const LOGIN_URL = "/prijava";
export const REGISTER_URL = "/registracija";
export const FORGOT_PASSWORD_URL = "/zaboravljena-lozinka";
export const CHANGE_PASSWORD_URL = "/promjena-lozinke";

export const CHANGE_PHONE_NUMBER_URL = "/promjena-telefonskog-broja";
export const CHANGE_DEFAULT_LOCATION_URL = "/promjena-podrazumjevane-lokacije";
export const CHANGE_AVATAR_IMAGE_URL = "/promjena-profilne-slike";

export const DASHBOARD_URL = "/kontrolna-tabla";
export const NOTIFICATIONS_URL = "/obavjestenja";
export const MESSAGES_URL = "/poruke";
export const CART_URL = "/korpa"

export const NEW_LISTING_URL = "/objavi-oglas";
export const MY_LISTINGS_URL = "/moji-oglasi";
export const MY_FAVORITES_URL = "/moji-favoriti";
export const MY_SEARCHES_URL = "/moje-pretrage";
export const BLOCKED_USERS_LIST = "/blokirani-korisnici";

export const FINANCE_URL = "/uplate";
export const TOKENS_URL = "/tokeni";
export const PLANS_URL = "/paketi";
export const ORDERS_URL = "/prodaje";
export const REQUESTS_URL = "/zahtjevi";
export const PURCHASES_URL = "/kupovine";

export const MY_ACCOUNT_URL = "/moj-nalog";
export const UPDATE_ACCOUNT_URL = "/moj-nalog/izmjena";
export const SETTINGS_URL = "/podesavanja";
