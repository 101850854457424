import { DateTime } from 'luxon';
//import * as AppConstants from '../config/AppConstants';


export function translateType(type) {
    switch (type) {
        case "SALE":
            return "Prodaja/kupovina";
        case "RENT":
            return "Iznajmljivanje";
        case "JOB":
            return "Posao";
        case "SERVICE":
            return "Usluga";
        case "GIFT":
            return "Poklon";
        case "MISC":
            return "Razno";
        default:
            return "";
    }
}

export function translateDirection(direction) {
    switch (direction) {
        case "OFFER":
            return "Nudim";
        case "DEMAND":
            return "Tražim";
        default:
            return "";
    }
}

export function translateStatus(status) {
    switch (status) {
        case "DRAFT":
            return "U pripremi";
        case "PUBLISHED":
            return "Objavljen";
        case "EXPIRED":
            return "Istekao";
        case "HIDDEN":
            return "Završen";
        case "BANNED":
            return "Banovan";
        case "DELETED":
            return "Obrisan";
        default:
            return "";
    }
}

export function translateState(state) {
    switch (state) {
        case "NEW":
            return "Novo";
        case "USED":
            return "Korišćeno";
        case "UNUSED":
            return "Nekorišćeno";
        case "BROKEN":
            return "Oštećeno";
        default:
            return "";
    }
}

export function translateUom(uom) {
    switch (uom) {
        case "PIECE":
            return "Komad";
        case "KILOGRAM":
            return "Kilogram";
        case "METER":
            return "Metar";
        case "SQUARE_METER":
            return "Kvadratni metar";
        case "LITER":
            return "Litar";
        default:
            return "";
    }
}

export function translateOrderStatus(status) {
    switch (status) {
        case "NEW":
            return "Nova";
        case "ACCEPTED":
            return "Prihvaćena";
        case "REJECTED":
            return "Odbijena";
        case "SHIPPED":
            return "U dostavi";
        case "FINISHED":
            return "Završena";
        case "REVOKED":
            return "Poništena";
        default:
            return "";
    }
}

export function translateGender(gender) {
    switch (gender) {
        case "MALE":
            return "Muški";
        case "FEMALE":
            return "Ženski";
        default:
            return "";
    }
}

export function formatD(datetime) {
    return DateTime.fromISO(datetime).toFormat("dd.MM.yyyy.");
}

export function formatT(datetime) {
    return DateTime.fromISO(datetime).toFormat("HH:mm");
}

export function formatT2(datetime) {
    return DateTime.fromISO(datetime).toFormat("HH:mm:ss");
}

export function formatDt(datetime) {
    return DateTime.fromISO(datetime).toFormat("dd.MM.yyyy. HH:mm");
}

export function formatDtDiscount(datetime) {
  return DateTime.fromISO(datetime).toFormat("yyyy-MM-dd HH:mm:ss");
}

export function addDt(datetime, duration) {
    return DateTime.fromISO(datetime).plus(duration).toFormat("dd.MM.yyyy. HH:mm");
}

export function subtractDt(datetime, duration) {
    return DateTime.fromISO(datetime).minus(duration).toFormat("dd.MM.yyyy. HH:mm");
}


export function getCategoryBrowserUrl(categoryId, slug) {
    return "/" + slug + "/kategorija/" + categoryId;
}


export function getBreadcrumbArray(categories, currentCategory) {
    const currentPath = currentCategory.path;
    const pathIds = currentPath.split(".");
    return pathIds.map(categoryId => categories.find(category => String(category.id) === String(categoryId)))
}

export const handlePrice = (price) => {
    const formattedPrice = Number(price).toLocaleString("da-DK", { minimumFractionDigits: 2 }) + " KM";
    if (formattedPrice.includes(",00")) {
        return formattedPrice.split(",").shift() + " KM";
    } else return formattedPrice;
};

export function handleFiltersSuitableForSpecificCategory() {
    return [
      {
        categoryId: 50012,
        categoryName: "Auto-moto",
        type: ["SALE", "RENT"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50015,
        categoryName: "Nekretnine",
        type: ["SALE", "RENT"],
        state: ["NEW", "USED"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },

      {
        categoryId: 50010,
        categoryName: "Odjeća, obuća, modni dodaci",
        type: ["SALE", "RENT", "GIFT"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },

      {
        categoryId: 50008,
        categoryName: "Sport i sportska oprema",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },

      {
        categoryId: 50022,
        categoryName: "Računari i oprema",
        type: ["SALE", "RENT", "GIFT"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50021,
        categoryName: "TV, Video i foto oprema, dronovi",
        type: ["SALE", "RENT", "GIFT"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50020,
        categoryName: "Moja kuća",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50014,
        categoryName: "Mobilni uređaji i oprema",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50011,
        categoryName: "Mašine i alati",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50019,
        categoryName: "Građevinarstvo",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50018,
        categoryName: "Kućni ljubimci",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50003,
        categoryName: "Muzika i instrumenti",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50016,
        categoryName: "Dječiji kutak",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50000,
        categoryName: "Ljepota i zdravlje",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50002,
        categoryName: "Antikviteti, kolekcionarstvo i umjetnost",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50001,
        categoryName: "Turizam",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        offer: ["OFFER", "DEMAND"],
        other_filters: ["Samo sa unesenom cijenom", "Samo sa fotografijom"],
      },
  
      {
        categoryId: 50009,
        categoryName: "Usluge",
        type: ["SERVICE", "GIFT"],
        offer: ["OFFER", "DEMAND"],
        other_filters: ["Samo sa unesenom cijenom", "Samo sa fotografijom"],
      },
  
      {
        categoryId: 50017,
        categoryName: "Knjižara",
        type: ["SALE", "RENT", "GIFT"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50023,
        categoryName: "Poslovi",
        type: ["JOB"],
        offer: ["OFFER", "DEMAND"],
      },
  
      {
        categoryId: 50007,
        categoryName: "Ishrana",
        type: ["SALE", "GIFT", "Usluge"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50006,
        categoryName: "Karte",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
  
      {
        categoryId: 50004,
        categoryName: "Poklon",
        type: ["GIFT"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa fotografijom",
        ],
      },
  
      {
        categoryId: 50013,
        categoryName: "Zabava",
        type: ["RENT", "SERVICE"],
        state: ["NEW", "USED"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
        ],
      },
  
      {
        categoryId: 50005,
        categoryName: "Razno",
        type: ["SALE", "RENT", "GIFT", "SERVICE"],
        state: ["NEW", "USED", "UNUSED", "BROKEN"],
        offer: ["OFFER", "DEMAND"],
        other_filters: [
          "Samo sa dostavom",
          "Samo sa besplatnom dostavom",
          "Samo sa unesenom cijenom",
          "Samo sa fotografijom",
          "Korisnik prihvata zamjenu",
        ],
      },
    ];
};

export function handleRootCategoriesWithValidTypes() {
  return [
    {
        "id": 50020,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Moja kuća",
        "slug": "moja-kuca",
        "fillable": false,
        "root": true,
        "path": "50020",
        "iconId": "furniture-sofa-chair.svg",
        "thumbnail": "l2ANdpuxd0tm4hYiDgkP-24.webp",
        "sortingOrder": 7,
        "supportsDelivery": true
    },
    {
        "id": 50016,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Dječiji kutak",
        "slug": "djeciji-kutak",
        "fillable": false,
        "root": true,
        "path": "50016",
        "iconId": "baby-children-toys-baby-child.svg",
        "thumbnail": "ptXSXsO5DphJpgQHPorM-78.webp",
        "sortingOrder": 13,
        "supportsDelivery": true
    },
    {
        "id": 50015,
        "types": [
            "SALE",
            "RENT"
        ],
        "name": "Nekretnine",
        "slug": "nekretnine",
        "fillable": false,
        "root": true,
        "path": "50015",
        "iconId": "real-estate-home-house-big.svg",
        "thumbnail": "Qf0CJuj57hdgMABaZRte-8.webp",
        "sortingOrder": 2,
        "supportsDelivery": false
    },
    {
        "id": 50022,
        "types": [
            "SALE",
            "RENT",
            "GIFT"
        ],
        "name": "Računari i oprema",
        "slug": "racunari-i-oprema",
        "fillable": false,
        "root": true,
        "path": "50022",
        "iconId": "computers-devices-electronics-laptop.svg",
        "thumbnail": "Ap7ZIwwigWfZKroTN7ni-19.webp",
        "sortingOrder": 4,
        "supportsDelivery": true
    },
    {
        "id": 50012,
        "types": [
            "RENT",
            "SALE"
        ],
        "name": "Auto-moto",
        "slug": "auto-moto",
        "fillable": false,
        "root": true,
        "path": "50012",
        "iconId": "car-service-parts-car-4.svg",
        "thumbnail": "maJspydrolW3cg6Ha5YM-1.webp",
        "sortingOrder": 1,
        "supportsDelivery": true
    },
    {
        "id": 50011,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Mašine i alati",
        "slug": "masine-i-alati",
        "fillable": false,
        "root": true,
        "path": "50011",
        "iconId": "interface-essential-tools-settings.svg",
        "thumbnail": "kj3Zl3z2iBD8U2kTFtBe-47.webp",
        "sortingOrder": 9,
        "supportsDelivery": true
    },
    {
        "id": 50010,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Odjeća, obuća, modni dodaci",
        "slug": "odjeca-obuca-modni-dodaci",
        "fillable": false,
        "root": true,
        "path": "50010",
        "iconId": "clothes-accessories-shirt-1.svg",
        "thumbnail": "grEgYuF76oLH4MaCwAGR-52.webp",
        "sortingOrder": 3,
        "supportsDelivery": true
    },
    {
        "id": 50009,
        "types": [
            "SERVICE",
            "GIFT"
        ],
        "name": "Usluge",
        "slug": "usluge",
        "fillable": false,
        "root": true,
        "path": "50009",
        "iconId": "transportation-truck-2.svg",
        "thumbnail": "YSjkU4I9938hGhYPkuho-33.webp",
        "sortingOrder": 17,
        "supportsDelivery": true
    },
    {
        "id": 50008,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Sport i sportska oprema",
        "slug": "sport-i-sportska-oprema",
        "fillable": false,
        "root": true,
        "path": "50008",
        "iconId": "sport-fitness-gate-football.svg",
        "thumbnail": "TIhFkH3X9Ln9FovwR4tg-56.webp",
        "sortingOrder": 6,
        "supportsDelivery": true
    },
    {
        "id": 50007,
        "types": [
            "SALE",
            "GIFT"
        ],
        "name": "Ishrana",
        "slug": "ishrana",
        "fillable": false,
        "root": true,
        "path": "50007",
        "iconId": "fast-food-drink-burger-1.svg",
        "thumbnail": "MnZZrMFtfevOGKMHLo6V-93.webp",
        "sortingOrder": 20,
        "supportsDelivery": true
    },
    {
        "id": 50006,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Karte",
        "slug": "karte",
        "fillable": false,
        "root": true,
        "path": "50006",
        "iconId": "travel-passport-ticket-travel.svg",
        "thumbnail": "JUwo9zossofJeA3xrCx4-944.webp",
        "sortingOrder": 21,
        "supportsDelivery": true
    },
    {
        "id": 50005,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Razno",
        "slug": "razno",
        "fillable": false,
        "root": true,
        "path": "50005",
        "iconId": "money-atm-money-banknote.svg",
        "thumbnail": "8UsxSI9U9pxzeTpxYBAq-96.webp",
        "sortingOrder": 24,
        "supportsDelivery": true
    },
    {
        "id": 50004,
        "types": [
            "GIFT"
        ],
        "name": "Pokloni",
        "slug": "pokloni",
        "fillable": true,
        "root": true,
        "path": "50004",
        "iconId": "romance-wedding-gift-box-1.svg",
        "thumbnail": "qVGLWQwa4PspDgnBHKEg-94.webp",
        "sortingOrder": 22,
        "supportsDelivery": true
    },
    {
        "id": 50003,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Muzika i instrumenti",
        "slug": "muzika-i-instrumenti",
        "fillable": false,
        "root": true,
        "path": "50003",
        "iconId": "music-audio-note-1.svg",
        "thumbnail": "2mXBIRsbae7V1gRZjhOZ-82.webp",
        "sortingOrder": 12,
        "supportsDelivery": true
    },
    {
        "id": 50000,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Ljepota i zdravlje ",
        "slug": "ljepota-i-zdravlje",
        "fillable": false,
        "root": true,
        "path": "50000",
        "iconId": "beauty-body-care-cream.svg",
        "thumbnail": "PMzJ5g3Jik5O3pvGWGya-927.webp",
        "sortingOrder": 14,
        "supportsDelivery": true
    },
    {
        "id": 50001,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Turizam",
        "slug": "turizam",
        "fillable": false,
        "root": true,
        "path": "50001",
        "iconId": "travel-baggage-1.svg",
        "thumbnail": "D6DQyCVKZF441peK3PLG-65.webp",
        "sortingOrder": 16,
        "supportsDelivery": false
    },
    {
        "id": 50013,
        "types": [
            "SERVICE",
            "RENT",
        ],
        "name": "Zabava",
        "slug": "zabava",
        "fillable": true,
        "root": true,
        "path": "50013",
        "iconId": "transportation-air-balloon.svg",
        "thumbnail": "3wkuzWraJ0XfpKfqER04-92.webp",
        "sortingOrder": 23,
        "supportsDelivery": false
    },
    {
        "id": 50023,
        "types": [
            "JOB"
        ],
        "name": "Poslovi",
        "slug": "poslovi",
        "fillable": true,
        "root": true,
        "path": "50023",
        "iconId": "office-work-suitcase-portfolio-1.svg",
        "thumbnail": "V1NhSwkwpmncsWUZzUGk-95.webp",
        "sortingOrder": 19,
        "supportsDelivery": false
    },
    {
        "id": 50002,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Antikviteti, kolekcionarstvo i umjetnost ",
        "slug": "antikviteti-kolekcionarstvo-i-umjetnost",
        "fillable": false,
        "root": true,
        "path": "50002",
        "iconId": "furniture-appliance-1.svg",
        "thumbnail": "pUQ8rr928JcdivO6kSFU-88.webp",
        "sortingOrder": 15,
        "supportsDelivery": true
    },
    {
        "id": 50014,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Mobilni uređaji i oprema",
        "slug": "mobilni-ureaji-i-oprema",
        "fillable": false,
        "root": true,
        "path": "50014",
        "iconId": "computers-devices-electronics-iphone-mobile-phone.svg",
        "thumbnail": "LB0k3Hv7z1ZL93pM8XgI-28.webp",
        "sortingOrder": 8,
        "supportsDelivery": true
    },
    {
        "id": 50017,
        "types": [
            "SALE",
            "RENT",
            "GIFT"
        ],
        "name": "Knjižara",
        "slug": "knjizara",
        "fillable": false,
        "root": true,
        "path": "50017",
        "iconId": "interface-essential-book-2.svg",
        "thumbnail": "y8MhWmUxkDfc0jvSfIVt-59.webp",
        "sortingOrder": 18,
        "supportsDelivery": true
    },
    {
        "id": 50018,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Kućni ljubimci",
        "slug": "kucni-ljubimci",
        "fillable": false,
        "root": true,
        "path": "50018",
        "iconId": "pets-animals-animal-print-1.svg",
        "thumbnail": "sh3OBGN8Fe6RRrdnBnUG-83.webp",
        "sortingOrder": 11,
        "supportsDelivery": true
    },
    {
        "id": 50019,
        "types": [
            "SALE",
            "RENT",
            "SERVICE",
            "GIFT"
        ],
        "name": "Građevinarstvo",
        "slug": "graevinarstvo",
        "fillable": false,
        "root": true,
        "path": "50019",
        "iconId": "office-work-suitcase-portfolio-1.svg",
        "thumbnail": "RAW8rQIeCtbUjTG0w2UV-50144.png",
        "sortingOrder": 10,
        "supportsDelivery": true
    },
    {
        "id": 50021,
        "types": [
            "SALE",
            "RENT",
            "GIFT"
        ],
        "name": "TV, video i foto oprema, dronovi",
        "slug": "tv-video-i-foto-oprema-dronovi",
        "fillable": false,
        "root": true,
        "path": "50021",
        "iconId": "computers-devices-electronics-screen-monitor.svg",
        "thumbnail": "6zotxPlAy7o3bwL3Jd1S-70.webp",
        "sortingOrder": 5,
        "supportsDelivery": true
    }
  ];
};
   

export function getUserAvatarImageUrl(user) {
    return `https://www.roba.ba/i_users/${user.id}/avatar/${user.avatarImage}`
    //return AppConstants.BASE_API_URL + "/files/users/" + user.id + "/avatar/" + user.avatarImage;
}

export function getPhotoUrl(listingId, fileName) {
    return `https://www.roba.ba/i_listings/${listingId}/${fileName}`;
    //return AppConstants.BASE_API_URL + "/files/listings/" + listingId + "/photos/" + fileName;
}

export function getSmallThumbnailUrl(listingId, fileName) {
    return `https://www.roba.ba/i_listings/${listingId}/thumbnails/150x150/${fileName}`;
    //return AppConstants.BASE_API_URL + "/files/listings/" + listingId + "/photos/thumbnails/150x150/" + fileName;
}

export function getMediumThumbnailUrl(listingId, fileName) {
    return `https://www.roba.ba/i_listings/${listingId}/thumbnails/300x300/${fileName}`;
    //return AppConstants.BASE_API_URL + "/files/listings/" + listingId + "/photos/thumbnails/300x300/" + fileName;
}

export function getLargeThumbnailUrl(listingId, fileName) {
    return `https://www.roba.ba/i_listings/${listingId}/thumbnails/400x400/${fileName}`;
    //return AppConstants.BASE_API_URL + "/files/listings/" + listingId + "/photos/thumbnails/400x400/" + fileName;
}

export function getCategoryThumbnailUrl(categoryId, fileName) {
    return `https://www.roba.ba/i_mages/uploads/categories/${categoryId}/thumbnail/${fileName}`;
    //return AppConstants.BASE_API_URL + "/files/categories/" + categoryId + "/thumbnail/" + fileName;
}

export function getSvgIconUrl(color, fileName) {
    if (fileName === undefined) {
        return `https://www.roba.ba/i_mages/icons/${color}/default.svg`;
    } else {
        return `https://www.roba.ba/i_mages/icons/${color}/${fileName}`;
    };
}
