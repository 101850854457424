import React from "react";
import { ThreeDots } from "react-loader-spinner";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function Loader() {
  return (
    <div className="w-full h-screen overflow-hidden flex flex-col items-center justify-center">
      <ThreeDots
        height="50"
        width="50"
        radius="9"
        color="#5e418f"
        ariaLabel="three-dots-loading"
        visible={true}
      />
      <p className="text-center text-purple text-xl font-semibold">
        Učitavanje...
      </p>
    </div>
  );
}

export default Loader;
