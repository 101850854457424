import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../components/basic/Button';
import RobaSvg from '../../assets/images/RobaSvg';

function Page404() {
    return (
        <div className="h-screen flex items-center justify-center">
            <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
                <div className="max-w-md">
                    <div className="py-4 text-5xl font-dark font-bold">Greška 404</div>
                    <p className="py-4 text-2xl md:text-3xl font-light leading-normal">Tražena stranica ne postoji</p>
                    <p className="py-3"></p>
                    <NavLink to="/" title="Nazad na početnu"><Button variant="primary">Nazad na početnu</Button></NavLink>
                </div>
                <div className="max-w-lg">
                    <RobaSvg className="w-72 mt-12 md:w-60 md:ml-16 md:mt-0 lg:w-96 lg:ml-20 lg:mt-0" />
                </div>
            </div>
        </div>
    );
}

export default Page404;