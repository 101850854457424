// Import free-solid-svg-icons
import {
  faSearch,
  faAt,
  faPlus,
  faSignInAlt,
  faUserPlus,
  faSignOutAlt,
  faBars,
  faThLarge,
  faList,
  faHeart,
  faStoreAlt,
  faShoppingCart,
  faBox,
  faCoins,
  faWallet,
  faCog,
  faUser,
  faBell,
  faEnvelope,
  faTimes,
  faFire,
  faSync,
  faStar,
  faPen,
  faKey,
  faSyncAlt,
  faCheck,
  faChevronRight,
  faFilter,
  faThList,
  faSave,
  faMinus,
  faPaperPlane,
  faInbox,
  faHeartBroken,
  faExclamationTriangle,
  faCreditCard,
  faFileInvoice,
  faChevronLeft,
  faTags,
  faCartPlus,
  faExclamationCircle,
  faFileAlt,
  faImage,
  faMapMarker,
  faDollarSign,
  faTruck,
  faChevronUp,
  faChevronDown,
  faCheckCircle,
  faTimesCircle,
  faTrashAlt,
  faUpload,
  faFolderPlus,
  faAlignLeft,
  faStore,
  faHashtag,
  faEye,
  faPhone,
  faTrash,
  faCalendarDay,
  faExchangeAlt,
  faBan,
  faShareAlt,
  faCalendarAlt,
  faMapMarkerAlt,
  faGlobe,
  faStarHalf,
  faBold,
  faItalic,
  faUnderline,
  faCode,
  faListOl,
  faListUl,
  faQuoteRight,
  faMoneyBill,
  faMoneyCheck,
  faCaretUp,
  faCaretDown,
  faUserTimes,
  faBullhorn,
  faShieldVirus,
  faShieldAlt,
  faPaperclip,
  faDownload,
  faFileDownload,
  faPercent,
  faBuilding
} from "@fortawesome/free-solid-svg-icons";

// Import free-brands-svg-icons
import {
  faFacebook as fabfaFacebook,
  faInstagram as fabInstagram,
  faYoutube as fabYoutube,
  faTwitter as fabTwitter,
  faAppStore as fabAppStore,
  faGooglePlay as fabGooglePlay,
  faPinterest as fabPinterest,
  faViber as fabViber,
  faWhatsapp as fabWhatsapp,
  faTiktok as fabTiktok
} from "@fortawesome/free-brands-svg-icons";

// Import free-regular-svg-icons
import {
  faUser as farUser,
  faCheckCircle as farCheckCircle,
  faTrashAlt as farTrashAlt,
  faEnvelope as farEnvelope,
  faStar as farStar,
  faMap as farMap,
} from "@fortawesome/free-regular-svg-icons";

// Export individual Icons
export const IconsLibrary = {
  faSearch,
  faPlus,
  faSignInAlt,
  faUserPlus,
  faSignOutAlt,
  faBars,
  faThLarge,
  faList,
  faHeart,
  faStoreAlt,
  faShoppingCart,
  faBox,
  faCoins,
  faWallet,
  faMoneyBill,
  faMoneyCheck,
  faCog,
  faUser,
  faBell,
  faEnvelope,
  faTimes,
  faFire,
  faSync,
  faStar,
  faPen,
  faKey,
  faSyncAlt,
  faCheck,
  faChevronRight,
  faFilter,
  faThList,
  faSave,
  faMinus,
  faPaperPlane,
  faInbox,
  faHeartBroken,
  faExclamationTriangle,
  faCreditCard,
  faFileInvoice,
  faChevronLeft,
  faTags,
  faCartPlus,
  faExclamationCircle,
  faFileAlt,
  faImage,
  faMapMarker,
  faDollarSign,
  faTruck,
  faChevronUp,
  faChevronDown,
  faCheckCircle,
  faTimesCircle,
  faTrashAlt,
  faUpload,
  faFolderPlus,
  faAlignLeft,
  faStore,
  faHashtag,
  faEye,
  faAt,
  faPhone,
  faTrash,
  faCalendarDay,
  faExchangeAlt,
  faBan,
  faShareAlt,
  faCalendarAlt,
  faGlobe,
  faStarHalf,
  faBold,
  faItalic,
  faCode,
  faListOl,
  faListUl,
  faQuoteRight,
  faUnderline,
  faCaretUp,
  faCaretDown,
  fabfaFacebook,
  fabInstagram,
  fabYoutube,
  fabTwitter,
  fabAppStore,
  fabGooglePlay,
  fabPinterest,
  fabViber,
  fabWhatsapp,
  fabTiktok,
  farUser,
  farCheckCircle,
  farTrashAlt,
  farEnvelope,
  farStar,
  farMap,
  faMapMarkerAlt,
  faUserTimes,
  faBullhorn,
  faShieldVirus,
  faShieldAlt,
  faPaperclip,
  faDownload,
  faFileDownload,
  faPercent,
  faBuilding
};
