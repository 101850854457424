import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { IconsLibrary } from '../util/FontAwesomeIconsUtil';

const ToastContext = React.createContext();

const CloseButton = ({ closeToast }) => (
    <FontAwesomeIcon
        icon={IconsLibrary.faTimes}
        className="text-xs text-white mr-1 mt-1"
        onClick={closeToast}
    />
);

export const ToastProvider = ({ children }) => {

    const [contextStyleColor, setContextStyleColor] = React.useState("");

    // const contextClass = {
    //     success: "bg-purple",
    //     error: "bg-red-600",
    //     info: "bg-gray-dark",
    //     warning: "bg-yellow",
    //     default: "bg-purple",
    //     dark: "bg-white-600 font-gray-300",
    // };

    const notify = React.useCallback(message => toast(message.text, {
        toastId: message.id,

    }), []);

    const contextPayload = React.useMemo(() => ({
        notify,
        setContextStyleColor,
        contextStyleColor
    }), [notify, contextStyleColor, setContextStyleColor]);

    return (
        <ToastContext.Provider value={contextPayload}>
            <ToastContainer
                toastClassName={() => `${!!contextStyleColor ? contextStyleColor : "bg-purple"} relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer`}
                bodyClassName={() => "text-sm font-white block p-3"}
                closeButton={CloseButton}
                hideProgressBar
            />
            {children}
        </ToastContext.Provider>
    )
}

export const useToastContext = () => React.useContext(ToastContext);