import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";

// custom auth and error handling components
import PrivateRoute from './shared/auth/PrivateRoute'
import PublicRoute from './shared/auth/PublicRoute'
import { ErrorHandler } from './shared/error/ErrorHandler';
import { AuthProvider } from './shared/auth/AuthContext';

// toast context
import { ToastProvider } from './shared/state/ToastContext';

// global state context
import { GlobalProvider } from './shared/state/GlobalContext';

// global api client
import { ApiProvider } from './shared/client/ApiContext';

// layout elements
// import Header from './shared/layout/header/Header';

// site page components
import Page404 from './shared/error/Page404'
import Loader from './shared/components/basic/Loader';
//import Home from './modules/home/Home'

// scroll to top on route change component
import ScrollToTop from './shared/components/basic/ScrollToTop';

// time ago library
import { register } from 'timeago.js';
import AsyncLoader from "./shared/util/AsyncLoadableUtil";
import { lazyWithRetry } from './lazyWithRetry';
import CookieConsent from 'react-cookie-consent';

// const Pricing = AsyncLoader({
//   loader: () => import('./modules/pages/Pricing' /* webpackChunkName: "pricing" */),
// });

const About = AsyncLoader({
  loader: () => import('./modules/pages/About' /* webpackChunkName: "about" */),
  timeout: 10000
});


const TermsandConditions = AsyncLoader({
  loader: () => import('./modules/pages/TermsandConditions' /* webpackChunkName: "TermsandConditions" */),
  timeout: 10000
});

const SafePayments = AsyncLoader({
  loader: () => import('./modules/pages/SafePayments' /* webpackChunkName: "SafePayments" */),
  timeout: 10000
});

const PrivacyPolicy = AsyncLoader({
  loader: () => import('./modules/pages/PrivacyPolicy' /* webpackChunkName: "PrivacyPolicy" */),
  timeout: 10000
});

const Impressum = AsyncLoader({
  loader: () => import('./modules/pages/Impressum' /* webpackChunkName: "impressum" */),
  timeout: 10000
});

const AllCategories = AsyncLoader({
  loader: () => import('./modules/home/AllCategories'),
  timeout: 10000
});

const LoginForm = AsyncLoader({
  loader: () => import('./modules/login/LoginForm'),
  timeout: 10000
});

const ResetPasswordRequest = AsyncLoader({
  loader: () => import('./modules/login/ResetPasswordRequest'),
  timeout: 10000
});

const ResetPasswordConfirm = AsyncLoader({
  loader: () => import('./modules/login/ResetPasswordConfirm'),
  timeout: 10000
});

const RegisterAccount = AsyncLoader({
  loader: () => import('./modules/account/RegisterAccount'),
  timeout: 10000
});

const ActivateAccount = AsyncLoader({
  loader: () => import('./modules/account/ActivateAccount'),
  timeout: 10000
});

const Search = AsyncLoader({
  loader: () => import('./modules/browse/Search'),
  timeout: 10000
});

const SearchUsers = AsyncLoader({
  loader: () => import('./modules/browse/SearchUsers'),
  timeout: 10000
});

const CategoryBrowser = AsyncLoader({
  loader: () => import('./modules/browse/CategoryBrowser'),
  timeout: 10000
});

const ViewListing = AsyncLoader({
  loader: () => import('./modules/listing/ViewListing'),
  timeout: 10000
});

const Cart = AsyncLoader({
  loader: () => import('./modules/cart/Cart'),
  timeout: 10000
});

const Checkout = AsyncLoader({
  loader: () => import('./modules/cart/Checkout'),
  timeout: 10000
});

const UserProfile = AsyncLoader({
  loader: () => import('./modules/profile/UserProfile'),
  timeout: 10000
});

const UserReviews = AsyncLoader({
  loader: () => import('./modules/profile/UserReviews'),
  timeout: 10000
});

const Invite = AsyncLoader({
  loader: () => import('./modules/home/Invite'),
  timeout: 10000
});

const Dashboard = AsyncLoader({
  loader: () => import('./modules/dashboard/Dashboard'),
  timeout: 10000
});

const Notifications = AsyncLoader({
  loader: () => import('./modules/notifications/Notifications'),
  timeout: 10000
});

const Conversations = AsyncLoader({
  loader: () => import('./modules/messages/Conversations'),
  timeout: 10000
});

const Conversation = AsyncLoader({
  loader: () => import('./modules/messages/Conversation'),
  timeout: 10000
});

const PublishForm = AsyncLoader({
  loader: () => import('./modules/listing/PublishForm'),
  timeout: 10000
});

const MyListings = AsyncLoader({
  loader: () => import('./modules/listing/MyListings'),
  timeout: 10000
});

const EditListing = AsyncLoader({
  loader: () => import('./modules/listing/EditListing'),
  timeout: 10000
});

const EditListingDescription = AsyncLoader({
  loader: () => import('./modules/listing/EditListingDescription'),
  timeout: 10000
});

const EditListingDetails = AsyncLoader({
  loader: () => import('./modules/listing/EditListingDetails'),
  timeout: 10000
});

const EditListingAttributes = AsyncLoader({
  loader: () => import('./modules/listing/EditListingAttributes'),
  timeout: 10000
});

const EditListingPhotos = AsyncLoader({
  loader: () => import('./modules/listing/EditListingPhotos'),
  timeout: 10000
});

const EditListingVariations = AsyncLoader({
  loader: () => import('./modules/listing/EditListingVariations'),
  timeout: 10000
});

const Footer = AsyncLoader({
  loader: () => import('./shared/layout/footer/Footer'),
  timeout: 10000
});

const EditListingLocation = AsyncLoader({
  loader: () => import('./modules/listing/EditListingLocation'),
  timeout: 10000
});

const EditListingPrice = AsyncLoader({
  loader: () => import('./modules/listing/EditListingPrice'),
  timeout: 10000
});

const EditListingDelivery = AsyncLoader({
  loader: () => import('./modules/listing/EditListingDelivery'),
  timeout: 10000
});

const BoostListing = AsyncLoader({
  loader: () => import('./modules/listing/BoostListing'),
  timeout: 10000
});

const RenewListing = AsyncLoader({
  loader: () => import('./modules/listing/RenewListing'),
  timeout: 10000
});

const Favorites = AsyncLoader({
  loader: () => import('./modules/favorites/Favorites'),
  timeout: 10000
});

const SavedSearches = AsyncLoader({
  loader: () => import('./modules/saved-searches/SavedSearches'),
  timeout: 10000
});

const BlockedUsers = AsyncLoader({
  loader: () => import('./modules/blocked-user/BlockedUsers'),
  timeout: 10000
});

const Tokens = AsyncLoader({
  loader: () => import('./modules/tokens/Tokens'),
  timeout: 10000
});

const BuyTokens = AsyncLoader({
  loader: () => import('./modules/tokens/BuyTokens'),
  timeout: 10000
});

const FinanceTokens = AsyncLoader({
  loader: () => import('./modules/finance/FinanceTokens'),
  timeout: 10000
});

const FinanceTokensInvoice = AsyncLoader({
  loader: () => import('./modules/finance/FinanceTokensInvoice'),
  timeout: 10000
});

const FinancePlans = AsyncLoader({
  loader: () => import('./modules/finance/FinancePlans'),
  timeout: 10000
});

const FinancePlanInvoice = AsyncLoader({
  loader: () => import('./modules/finance/FinancePlanInvoice'),
  timeout: 10000
});

const Plans = AsyncLoader({
  loader: () => import('./modules/plans/Plans'),
  timeout: 10000
});

const BuyPlan = AsyncLoader({
  loader: () => import('./modules/plans/BuyPlan'),
  timeout: 10000
});

const Orders = AsyncLoader({
  loader: () => import('./modules/orders/Orders'),
  timeout: 10000
});

const Order = AsyncLoader({
  loader: () => import('./modules/orders/Order'),
  timeout: 10000
});

const Purchases = AsyncLoader({
  loader: () => import('./modules/orders/Purchases' /* webpackChunkName: "purchases" */),
  timeout: 10000
});

const Purchase = AsyncLoader({
  loader: () => import('./modules/orders/Purchase' /* webpackChunkName: "purchase" */),
  timeout: 10000
});

const MyAccount = AsyncLoader({
  loader: () => import('./modules/account/MyAccount' /* webpackChunkName: "my-account" */),
  timeout: 10000
});

const UpdateAccountDetails = AsyncLoader({
  loader: () => import('./modules/account/UpdateAccountDetails' /* webpackChunkName: "update-account-details" */),
  timeout: 10000
});

const ChangePassword = AsyncLoader({
  loader: () => import('./modules/account/ChangePassword' /* webpackChunkName: "change-password" */),
  timeout: 10000
});

const UpdatePhoneNumber = AsyncLoader({
  loader: () => import('./modules/account/UpdatePhoneNumber' /* webpackChunkName: "update-phone-number" */),
  timeout: 10000
});

const VerifyPhoneNumber = AsyncLoader({
  loader: () => import('./modules/account/VerifyPhoneNumber' /* webpackChunkName: "verify-phone-number" */),
  timeout: 10000
});

const UpdateDefaultLocation = AsyncLoader({
  loader: () => import('./modules/account/UpdateDefaultLocation' /* webpackChunkName: "update-default-location" */),
  timeout: 10000
});

const VerifyDefaultLocation = AsyncLoader({
  loader: () => import('./modules/account/VerifyDefaultLocation' /* webpackChunkName: "verify-default-location" */),
  timeout: 10000
});

const UpdateAvatarImage = AsyncLoader({
  loader: () => import('./modules/account/UpdateAvatarImage' /* webpackChunkName: "update-avatar-image" */),
  timeout: 10000
});

const Settings = AsyncLoader({
  loader: () => import('./modules/settings/Settings' /* webpackChunkName: "settings" */),
  timeout: 10000
});

const Home = lazyWithRetry(() => import(
  /* webpackChunkName: "homepage" */ './modules/home/Home'
));

// configure timeago library
const localeFunc = (number, index, totalSec) => {

  if (number === 1 || (number > 20 && number % 10 === 1)) {
    return [
      ['sada', 'sada'],
      ['prije %s sekundu', 'za %s sekundu'],
      ['prije 1 minutu', 'za 1 minutu'],
      ['prije %s minutu', 'za %s minutu'],
      ['prije 1 sat', 'za 1 sat'],
      ['prije %s sat', 'za %s sat'],
      ['prije 1 dan', 'za 1 dan'],
      ['prije %s dan', 'za %s dan'],
      ['prije 1 sedmicu', 'za 1 sedmicu'],
      ['prije %s sedmicu', 'za %s sedmicu'],
      ['prije 1 mjesec', 'za 1 mjesec'],
      ['prije %s mjesec', 'za %s mjesec'],
      ['prije 1 godinu', 'za 1 godinu'],
      ['prije %s godinu', 'za %s godinu']
    ][index];
  }

  if ((number > 1 && number < 5) || (number > 20 && (number % 10 > 1 && number % 10 < 5))) {
    return [
      ['sada', 'sada'],
      ['prije %s sekunde', 'za %s sekunde'],
      ['prije 1 minutu', 'za 1 minutu'],
      ['prije %s minute', 'za %s minute'],
      ['prije 1 sat', 'za 1 sat'],
      ['prije %s sata', 'za %s sata'],
      ['prije 1 dan', 'za 1 dan'],
      ['prije %s dana', 'za %s dana'],
      ['prije 1 sedmicu', 'za 1 sedmicu'],
      ['prije %s sedmice', 'za %s sedmice'],
      ['prije 1 mjesec', 'za 1 mjesec'],
      ['prije %s mjeseca', 'za %s mjeseca'],
      ['prije 1 godinu', 'za 1 godinu'],
      ['prije %s godine', 'za %s godine']
    ][index];
  }

  if ((number >= 5 && number < 21) || number % 10 >= 5 || number % 10 === 0) {
    return [
      ['sada', 'sada'],
      ['prije %s sekundi', 'za %s sekundi'],
      ['prije 1 minutu', 'za 1 minutu'],
      ['prije %s minuta', 'za %s minuta'],
      ['prije 1 sat', 'za 1 sat'],
      ['prije %s sati', 'za %s sati'],
      ['prije 1 dan', 'za 1 dan'],
      ['prije %s dana', 'za %s dana'],
      ['prije 1 sedmicu', 'za 1 sedmicu'],
      ['prije %s sedmica', 'za %s sedmica'],
      ['prije 1 mjesec', 'za 1 mjesec'],
      ['prije %s mjeseci', 'za %s mjeseci'],
      ['prije 1 godinu', 'za 1 godinu'],
      ['prije %s godina', 'za %s godina']
    ][index];
  }
};

// register locale with timeagos
register('bs_BA', localeFunc);

// application shell component
function App() {

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <CookieConsent 
        location="bottom"
        buttonText="Razumijem"
        cookieName="robabaCookie"
        buttonStyle={{ background: '#ffd800', fontSize: '15px'}}
        ariaAcceptLabel="Razumijem">
          <span className="text-xs sm:text-sm md:text-base lg:text-base">Poštovani korisniče, naš sajt koristi kolačiće (cookies) u cilju poboljšanja kvaliteta stranice i korisničkog iskustva. Nastavkom korišćenja naše internet stranice prihvatate upotrebu kolačića.</span>
        </CookieConsent>
      <ScrollToTop />
      <ToastProvider>
        <AuthProvider>
          <GlobalProvider>
            <ApiProvider>
              <ErrorHandler>
                <Switch>
                  {/* home page */}
                  <PublicRoute exact path="/" component={Home} />

                  {/* public pages */}
                  {/* <PublicRoute exact path="/cjenovnik" component={Pricing} /> */}
                  <PublicRoute exact path="/o-nama" component={About} />
                  <PublicRoute exact path="/pravila-i-uslovi-koriscenja" component={TermsandConditions} />
                  <PublicRoute exact path="/sigurnost-placanja" component={SafePayments} />
                  <PublicRoute exact path="/politika-privatnosti" component={PrivacyPolicy} />
                  <PublicRoute exact path="/impressum" component={Impressum} />
                  <PublicRoute exact path="/kategorije" component={AllCategories} />

                  {/* authentication and registration */}
                  <PublicRoute exact path="/prijava" component={LoginForm} />
                  <PublicRoute exact path="/zaboravljena-lozinka" publicOnly component={ResetPasswordRequest} />
                  <PublicRoute exact path="/promjena-lozinke/potvrda" publicOnly component={ResetPasswordConfirm} />
                  <PublicRoute exact path="/registracija" publicOnly component={RegisterAccount} />
                  <PublicRoute exact path="/registracija/aktivacija" publicOnly component={ActivateAccount} />

                  {/* browse and search */}
                  <PublicRoute exact path="/pretraga" component={Search} />
                  <PublicRoute exact path="/pretraga/korisnici" component={SearchUsers} />
                  <PublicRoute exact path="/:categoryPath+/kategorija/:categoryId" component={CategoryBrowser} />
                  <PublicRoute exact path="/:listingPath+/oglas/:listingId" component={ViewListing} />

                  {/* shopping cart */}
                  <PublicRoute exact path="/korpa" component={Cart} />
                  <PrivateRoute exact path="/korpa/naruci/:sellerId" authenticated redirectToLogin component={Checkout} />

                  {/* users */}
                  <PublicRoute exact path="/profil/:username" component={UserProfile} />
                  <PublicRoute exact path="/profil/:username/ocjene" component={UserReviews} />

                  {/* invite */}
                  <PrivateRoute exact path="/pozivnica" authenticated redirectToLogin component={Invite} />

                  {/* control panel */}

                  {/* general */}
                  <PrivateRoute exact path="/kontrolna-tabla" authenticated redirectToLogin component={Dashboard} />
                  <PrivateRoute exact path="/obavjestenja" authenticated redirectToLogin component={Notifications} />
                  <PrivateRoute exact path="/poruke" authenticated redirectToLogin component={Conversations} />
                  <PrivateRoute exact path="/poruke/:conversationId" authenticated redirectToLogin component={Conversation} />

                  {/* listings */}
                  <PrivateRoute exact path="/objavi-oglas" authenticated redirectToLogin component={PublishForm} />
                  <PrivateRoute exact path="/moji-oglasi" authenticated redirectToLogin component={MyListings} />

                  <PrivateRoute exact path="/moji-oglasi/izmijeni/:listingId" authenticated redirectToLogin component={EditListing} />
                  <PrivateRoute exact path="/moji-oglasi/izmijeni/:listingId/opis" authenticated redirectToLogin component={EditListingDescription} />
                  <PrivateRoute exact path="/moji-oglasi/izmijeni/:listingId/detalji" authenticated redirectToLogin component={EditListingDetails} />
                  <PrivateRoute exact path="/moji-oglasi/izmijeni/:listingId/karakteristike" authenticated redirectToLogin component={EditListingAttributes} />
                  <PrivateRoute exact path="/moji-oglasi/izmijeni/:listingId/fotografije" authenticated redirectToLogin component={EditListingPhotos} />
                  <PrivateRoute exact path="/moji-oglasi/izmijeni/:listingId/varijacije" authenticated redirectToLogin component={EditListingVariations} />
                  <PrivateRoute exact path="/moji-oglasi/izmijeni/:listingId/lokacija" authenticated redirectToLogin component={EditListingLocation} />
                  <PrivateRoute exact path="/moji-oglasi/izmijeni/:listingId/cijena" authenticated redirectToLogin component={EditListingPrice} />
                  <PrivateRoute exact path="/moji-oglasi/izmijeni/:listingId/isporuka" authenticated redirectToLogin component={EditListingDelivery} />
                  <PrivateRoute exact path="/moji-oglasi/izdvoji/:listingId" authenticated redirectToLogin component={BoostListing} />
                  <PrivateRoute exact path="/moji-oglasi/obnovi/:listingId" authenticated redirectToLogin component={RenewListing} />

                  <PrivateRoute exact path="/moji-favoriti" authenticated redirectToLogin component={Favorites} />
                  <PrivateRoute exact path="/moje-pretrage" authenticated redirectToLogin component={SavedSearches} />
                  <PrivateRoute exact path="/blokirani-korisnici" authenticated redirectToLogin component={BlockedUsers} />

                  {/* transactions */}
                  <PrivateRoute exact path="/tokeni" authenticated redirectToLogin component={Tokens} />
                  <PrivateRoute exact path="/tokeni/kupi" authenticated redirectToLogin component={BuyTokens} />
                  <PrivateRoute exact path="/uplate" authenticated redirectToLogin component={FinanceTokens} />
                  <PrivateRoute exact path="/uplate/tokeni/racun/:invoiceId" authenticated redirectToLogin component={FinanceTokensInvoice} />
                  <PrivateRoute exact path="/uplate/paketi" authenticated redirectToLogin component={FinancePlans} />
                  <PrivateRoute exact path="/uplate/paketi/racun/:invoiceId" authenticated redirectToLogin component={FinancePlanInvoice} />
                  <PrivateRoute exact path="/paketi" authenticated redirectToLogin component={Plans} />
                  <PrivateRoute exact path="/paketi/kupi/:planId" authenticated company redirectToLogin component={BuyPlan} />
                  <PrivateRoute exact path="/prodaje" authenticated redirectToLogin component={Orders} />
                  <PrivateRoute exact path="/prodaje/:orderId" authenticated redirectToLogin component={Order} />
                  <PrivateRoute exact path="/kupovine" authenticated redirectToLogin component={Purchases} />
                  <PrivateRoute exact path="/kupovine/:purchaseId" authenticated redirectToLogin component={Purchase} />

                  {/* account */}
                  <PrivateRoute exact path="/moj-nalog" authenticated redirectToLogin component={MyAccount} />
                  <PrivateRoute exact path="/moj-nalog/izmjena" authenticated redirectToLogin component={UpdateAccountDetails} />
                  <PrivateRoute exact path="/promjena-lozinke" authenticated redirectToLogin component={ChangePassword} />
                  <PrivateRoute exact path="/promjena-telefonskog-broja" authenticated redirectToLogin component={UpdatePhoneNumber} />
                  <PrivateRoute exact path="/verifikacija-telefonskog-broja" authenticated redirectToLogin component={VerifyPhoneNumber} />
                  <PrivateRoute exact path="/promjena-podrazumjevane-lokacije" authenticated redirectToLogin component={UpdateDefaultLocation} />
                  <PrivateRoute exact path="/verifikacija-podrazumjevane-lokacije" authenticated redirectToLogin component={VerifyDefaultLocation} />
                  <PrivateRoute exact path="/promjena-profilne-slike" authenticated redirectToLogin component={UpdateAvatarImage} />
                  <PrivateRoute exact path="/podesavanja" authenticated redirectToLogin component={Settings} />

                  {/* not found */}
                  <PublicRoute component={Page404} />
                </Switch>
                <Footer />
              </ErrorHandler>
            </ApiProvider>
          </GlobalProvider>
        </AuthProvider>
      </ToastProvider>
      </Suspense>
    </Router>
  );
}

export default App;
